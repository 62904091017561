import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(29.5 16.799988)"
          d="M16.7432 33.252Q16.7432 39.5801 13.8098 42.9419Q10.8765 46.3037 5.31738 46.3037Q2.83447 46.3037 0.725098 45.4468L0.725098 38.7891Q2.54883 40.1733 4.81201 40.1733Q9.66797 40.1733 9.66797 32.9663L9.66797 14.2456L16.7432 14.2456L16.7432 33.252Z"
          fill="white"
        />
      </g>
      <path
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
