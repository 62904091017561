import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(29.5 16.799988)"
          d="M16.7432 33.252Q16.7432 39.5801 13.8098 42.9419Q10.8765 46.3037 5.31738 46.3037Q2.83447 46.3037 0.725098 45.4468L0.725098 38.7891Q2.54883 40.1733 4.81201 40.1733Q9.66797 40.1733 9.66797 32.9663L9.66797 14.2456L16.7432 14.2456L16.7432 33.252Z"
          fill="white"
        />
        <polygon
          id="Shape"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
